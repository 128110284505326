import {defineStore} from "pinia";

/**
 * @description: layout store
 */
const layoutStore = defineStore("layout", {
    state: () => ({
        // 是否折叠
        isCollapse: false,

    }),
    actions: {}

})


export default layoutStore