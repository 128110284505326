<template>
  <!-- 目录 -->
  <template v-if="item.type==='dir' && item.visible===1">
    <el-sub-menu :key="item.id" :index="item.id">
      <template #title>
        <el-icon v-if="item.icon">
          <component :is="item.icon" class="icon"/>
        </el-icon>
        <span>{{ item.title }}</span>
      </template>
      <sidebar-item v-for="childrenItem in item.children" :key="childrenItem.id" :item="childrenItem"/>
    </el-sub-menu>
  </template>

  <!--菜单-->
  <template v-else-if="item.type==='menu' && item.visible===1 ">
    <el-menu-item :key="item.path" :index="item.path" @click="handleClick(item)">
      <el-icon v-if="item.icon">
        <component :is="item.icon" class="icon"/>
      </el-icon>
      <template #title>{{ item.title }}</template>
    </el-menu-item>
  </template>

</template>


<script>
import tabsStore from "@/store/modules/tabs";

export default {
  name: "SidebarItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    // 菜单点击回调
    handleClick: function (item) {
      tabsStore().addTab(item);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>