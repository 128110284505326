import router from "@/router/index";


/**
 * 面包屑使用
 * 遍历树 为每个节点添加父节点信息
 */
const addParentInfo = data => {
    data.forEach(item => {
        if (item.children && item.children.length > 0) {
            item.children.forEach(child => {
                child.parentInfo = {title: item.title, icon: item.icon};
            })
            addParentInfo(item.children)
        }
    })
};

/**
 * 遍历树形结构 生成一维路由
 * @param data
 */
const openTreeToArray = data => {
    let array = [];
    data.forEach(item => {
        array.push(item);
        if (item.children && item.children.length > 0) {
            array.push(...openTreeToArray(item.children))
        }
    })
    return array;
};

/**
 * 添加动态路由
 * @param data
 */
const appendDynamicRoutes = data => {

    addParentInfo(data);

    let array = openTreeToArray(data);
    const routes = [];
    array.forEach(item => {
        // 路径为空 或 被停用
        if (!item.path || item.state === 0) {
            return
        }
        let route = {
            path: item.path,
            name: item.title,
            component: () => import(`@/pages/${item.component}`),
            meta: {
                title: item.title,
                permission: item.prems,
                icon: item.icon,
                visible: item.visible,
                parentInfo: item.parentInfo,
                params: item.query
            }
        }
        routes.push(route)
    })

    routes.push(...[
        {// 修改密码
            path: '/change/password',
            name: '修改密码',
            component: () => import('@/pages/ChangePassword'),
        },
        {// 500
            path: "/403",
            name: '服务器出错',
            component: () => import('@/pages/error/500')
        },
        {// 403
            path: "/403",
            name: '无权访问',
            component: () => import('@/pages/error/403')
        },
        {// 404页面
            path: "/:pathMatch(.*)*",
            name: '页面不存在',
            component: () => import('@/pages/error/404')
        }])

    let indexRoute = router.getRoutes()[0];
    indexRoute.children.push(...routes);
    router.addRoute(indexRoute);
};

export default {appendDynamicRoutes};