<template>
  <el-breadcrumb>
    <el-breadcrumb-item v-for="(item,index) in  breadcrumbList" :key="index">
      <!--      <el-icon v-if="item.icon">-->
      <!--        <component :is="item.icon" class="icon"/>-->
      <!--      </el-icon>-->
      {{ item.title }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>


<script setup>
import {ref, watch} from "vue";
import router from "@/router";
import {useRoute} from "vue-router";

let breadcrumbList = ref([])

let route = useRoute();

const initBreadcrumb = () => {
  breadcrumbList.value = [];
  route.matched.forEach(item => {
    if (!item.meta.title) return;
    let t = {
      title: item.meta.title,
      icon: item.meta.icon,
      path: item.path
    };
    // 如果父节点有信息
    if (item.meta && item.meta.parentInfo) {
      let tParent = {
        title: item.meta.parentInfo.title,
        icon: item.meta.parentInfo.icon,
      };
      breadcrumbList.value.push(tParent);
    }
    breadcrumbList.value.push(t)
  })


}

// 立即深度监听路由变化
watch(router.currentRoute, () => {
  initBreadcrumb();
}, {immediate: true, deep: true})


</script>

