<template>
  <div class="navbar">
    <div class="navbar-icon" @click="navbarIconClick">
      <el-icon v-if="layoutStore().isCollapse">
        <Expand/>
      </el-icon>
      <el-icon v-if="!layoutStore().isCollapse">
        <Fold/>
      </el-icon>
    </div>
    <breadcrumb/>
    <div class="navbar-right">
      <operation/>
    </div>
  </div>
</template>

<script setup>
import Breadcrumb from "@/layout/top/Breadcrumb";
import Operation from "@/layout/top/Operation";
import layoutStore from "@/store/modules/layout";

const navbarIconClick = () => {
  layoutStore().isCollapse = !layoutStore().isCollapse
}

</script>


<style lang="scss" scoped>

.navbar {
  width: 100%;
  height: 50px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: 0.1px solid #f4f6fa;
  padding: 0 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  .navbar-right {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: flex-end;
  }

}

.navbar-icon {
  font-size: 25px;
  height: 50px;
  // 上下居中
  display: flex;
  align-items: center;
  margin: 0 10px;
  // 手形状
  cursor: pointer;
}


</style>