import axios from 'axios'

import {ElMessage} from 'element-plus'
import userStore from "@/store/modules/user";


const baseURL = process.env.VUE_APP_BASE_API;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

/**
 * axios请求拦截器
 * @param {object} config axios请求配置对象
 * @return {object} 请求成功或失败时返回的配置对象或者promise error对象
 * **/
axios.interceptors.request.use(config => {
    // 这里的config包含每次请求的内容
    // let token = userStore().token;
    let token = userStore().token;
    if (token) {
        //  存在将api_token写入 request header
        // config.headers.Authorization = 'bearer ' + token //jwt
        config.headers.Authorization = token //jwt
    }
    return config
})

/**
 * axios 响应拦截器
 * @param {object} response 从服务端响应的数据对象或者error对象
 * @return {object} 响应成功或失败时返回的响应对象或者promise error对象
 **/
axios.interceptors.response.use(response => {
    // 传入业务状态码 如果业务状态码没有定义 则校验http状态码
    checkCode(response.data.code || response.status, response.data.msg);
    return response.data;
}, error => {
    checkHttpCode(error);
    return Promise.reject(error.response)
})


/**
 * http状态码 非200为异常
 * @param error
 * @returns {*}
 */
function checkHttpCode(error) {
    if (error.response === undefined) {
        ElMessage.error({
            message: '请求失败!',
        })
        return
    }
    checkCode(error.response.status);
}


/**
 * 状态码检查
 *
 * @param code
 * @param msg
 */
// eslint-disable-next-line no-unused-vars
function checkCode(code, msg = "出错了") {
    switch (code) {
        case 0:
            return;
        case 401:
            // ElMessageBox.alert('登录状态已过期，请重新登录', '系统提示', {
            //     confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning'
            // }).finally(() => {
            //     userStore().logout();
            // });
            ElMessage.error({ message : '登录状态已过期，请重新登录' })
            userStore().logout();
            break
        case 403:
            ElMessage.error({
                message: '拒绝访问', onClose: function () {
                    // router.push({name: 'error-403'})
                }
            })
            break
        case 404:
            ElMessage.error({
                message: '请求地址未找到', onClose: function () {
                    // router.push({name: 'error-404'})
                }
            })
            break
        case 500:
            ElMessage.error({
                message: '服务器错误', onClose: function () {
                    // router.push({name: 'error-500'})
                }
            })
            break
        default:
            return;
    }
}


/**
 * 发送get请求
 * @param url
 * @param params
 * @returns {Promise<unknown>}
 */
const get = function (url, params = {}) {
    return axios({
        baseURL,
        url,
        method: "get",
        params,
        timeout: 60000,
        traditional: true,
        // paramsSerializer: function (params) {
        //     return qs.stringify(params, {arrayFormat: 'repeat'})
        // }
    });
}

/**
 * 发送post请求
 */
const post = function (url, data = {}, params = {}, method = 'post') {
    return axios({
        baseURL,
        url, method, params, data, timeout: 60000,
    })
}


// 请求方式的配置
export default {
    post, get
}
